// If we experience perf issues again, uncomment this and, for function components, set MyComponent.whyDidYouRender = true.
// import '../common/whyDidYouRender.js'
import { ChakraProvider } from '@chakra-ui/react'
import { config, dom, library } from '@fortawesome/fontawesome-svg-core'
import { faWindows } from '@fortawesome/free-brands-svg-icons'
import {
  faSignal,
  faSignal1,
  faSignal2,
  faSignal3,
  faSignalAlt,
  faSignalAlt1,
  faSignalAlt2,
  faSignalAlt3,
} from '@fortawesome/pro-duotone-svg-icons'
import { faThumbtack as faThumbtackReg } from '@fortawesome/pro-regular-svg-icons'
import {
  faAngry,
  faArrowAltCircleUp,
  faArrowFromLeft,
  faArrowFromTop,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBold,
  faBullhorn,
  faCaretDown,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronRight,
  faClone,
  faCode,
  faComment,
  faCommentAltPlus,
  faCommentAlt,
  faCompass,
  faCompress,
  faConciergeBell,
  faEdit,
  faEllipsisH,
  faExpandArrowsAlt,
  faExternalLink,
  faEye,
  faFrownOpen,
  faGlobeAfrica,
  faGripVertical,
  faH1,
  faHandPointer,
  faHeart,
  faImage,
  faItalic,
  faLaughSquint,
  faLink,
  faMicrophone,
  faMicrophoneSlash,
  faMusic,
  faPencil,
  faPlay,
  faPlus,
  faPresentation,
  faRedo,
  faReply,
  faSadTear,
  faShare,
  faShieldAlt,
  faSlash,
  faSmile,
  faSmilePlus,
  faSmileWink,
  faStickyNote,
  faStrikethrough,
  faSurprise,
  faSwatchbook,
  faTh,
  faThLarge,
  faThumbsUp,
  faThumbtack,
  faTimes,
  faTrash,
  faTrumpet,
  faUnderline,
  faUpload,
  faUser,
  faUserSlash,
  faVideo,
  faVideoSlash,
  faVolume,
  faVolumeMute,
  faWindow,
  faH2,
  faH3,
  faQuoteRight,
  faListUl,
  faListOl,
  faHorizontalRule,
  faTable,
  faTasks,
  faPaintBrushAlt,
  faKeyboard,
  faICursor,
  faTerminal,
  faDatabase,
  faRemoveFormat,
  faHatWitch,
  faStop,
  faFill,
  faPaintRoller,
  faSearch,
  faPageBreak,
  faQuestion,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons'
import '@uppy/robodog/dist/robodog.css'
import { gsap } from 'gsap'
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { AppProps } from 'next/app'
import Head from 'next/head'
import 'promise-polyfill'
import React, { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import 'reveal.js/dist/reveal.css'
import 'reveal.js/dist/theme/simple.css'
import { AppProvider } from '../layout/AppProvider'
import '../modules/cards/cards.scss'
import '../modules/editor/editor.scss'
import '../modules/lobby/components/lobby.scss'
import '../modules/outline/components/outline.scss'
import '../modules/present/components/cursors.scss'
import '../modules/slide/components/slide.scss'
import '../modules/slidedocs/components/slidedoc.scss'
import { gammaTheme } from '../theme/theme'
import './global.scss'

gsap.registerPlugin(ScrollTrigger)
config.autoAddCss = false

library.add(
  faMicrophone,
  faMicrophoneSlash,
  faStop,
  faVideo,
  faVideoSlash,
  faEllipsisH,
  faConciergeBell,
  faHeart,
  faStickyNote,
  faImage,
  faComment,
  faCommentAlt,
  faThumbsUp,
  faLaughSquint,
  faPlus,
  faSurprise,
  faSadTear,
  faAngry,
  faExpandArrowsAlt,
  faCompress,
  faTh,
  faThLarge,
  faArrowRight,
  faArrowLeft,
  faPlay,
  faCheckSquare,
  faMusic,
  faPresentation,
  faSlash,
  faUpload,
  faSmile,
  faSmilePlus,
  faEdit,
  faSmileWink,
  faArrowFromLeft,
  faArrowFromTop,
  faTrumpet,
  faWindows,
  faFrownOpen,
  faBullhorn,
  faVolume,
  faVolumeMute,
  faTrash,
  faSwatchbook,
  faLink,
  faPencil,
  faCode,
  faBars,
  faTimes,
  faExternalLink,
  faUser,
  faCaretDown,
  faCheck,
  faThumbtack,
  faThumbtackReg,
  faClone,
  faUserSlash,
  faRedo,
  faReply,
  faCompass,
  faEye,
  faSignal,
  faSignal1,
  faSignal2,
  faSignal3,
  faSignalAlt,
  faSignalAlt1,
  faSignalAlt2,
  faSignalAlt3,
  faShieldAlt,
  faGlobeAfrica,
  faChevronDown,
  faChevronRight,
  faRemoveFormat,
  faHandPointer,
  faBold,
  faItalic,
  faUnderline,
  faStrikethrough,
  faCommentAltPlus,
  faGripVertical,
  faShare,
  faArrowAltCircleUp,
  faWindow,
  faH1,
  faH2,
  faH3,
  faQuoteRight,
  faListUl,
  faListOl,
  faHorizontalRule,
  faTable,
  faTasks,
  faPaintBrushAlt,
  faKeyboard,
  faICursor,
  faTerminal,
  faDatabase,
  faHatWitch,
  faFill,
  faPaintRoller,
  faSearch,
  faPageBreak,
  faQuestion,
  faCheckCircle,
  faTimesCircle
)

if (process.browser) {
  gsap.registerPlugin(ScrollToPlugin)
  gsap.registerPlugin(ScrollTrigger)
}

export function App({ Component, pageProps, ...restProps }: AppProps) {
  async function setupCanva() {
    if (window.Canva && window.Canva.DesignButton) {
      window.CanvaButtonAPI = await window.Canva.DesignButton.initialize({
        // https://www.canva.com/brand/api
        apiKey: 'Fge1XHshms6daoX9nvMrOcad',
      })
    }
  }

  useEffect(() => {
    setupCanva()
    const onPostMessage = ({ origin, data }) => {
      if (data.type === 'gamma.extension.outer-ready') {
        window.hasGammaChromeExtension = true
        window.gammaChromeExtensionVersion = data.version
      }
    }
    window.addEventListener('message', onPostMessage, false)
    return () => window.removeEventListener('message', onPostMessage, false)
  }, [])

  return (
    // Additional providers should be added within AppProvider
    <AppProvider>
      <ChakraProvider theme={gammaTheme} resetCSS={true}>
        <Head>
          <link rel="shortcut icon" href="/favicon.ico" />
          <style>{dom.css()}</style>
        </Head>
        <DndProvider backend={HTML5Backend}>
          <Component {...pageProps} />
        </DndProvider>
      </ChakraProvider>
    </AppProvider>
  )
}
export default App
