import React, { useContext } from 'react'

type SocketContextType = {
  socket?: SocketIOClient.Socket
}

export const SocketContext = React.createContext<SocketContextType>({} as any)

export function useSocketContext() {
  const val = useContext(SocketContext)
  return val
}
