import { createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import config from 'config'
import ReconnectingWebSocket from 'reconnecting-websocket'
import { getCookie } from 'utils/cookie'
export const httpLink = createHttpLink({
  uri: `${config('API_HOST')}/api/graphql`,
})

export const wsLink = process.browser
  ? new WebSocketLink({
      uri: `${config('WS_PROTOCOL')}://${config(
        'WS_HOST'
      )}/api/graphql/subscriptions`,
      options: {
        reconnect: true,
      },
      webSocketImpl: process.browser ? ReconnectingWebSocket : {},
    })
  : ({} as WebSocketLink)

export const setJwtLink = setContext((request, { headers }) => {
  const jwt = getCookie('gamma_jwt')
  return {
    headers: {
      ...headers,
      authorization: jwt ? `Bearer ${jwt}` : '',
    },
  }
})
