import { createSlice } from '@reduxjs/toolkit'

export interface DragDropState {
  dropTargets: {
    [key: string]: {
      scale: number
    }
  }
  dragSources: {
    [key: string]: {
      width: number
      height: number
      monitor: any
    }
  }
}
const initialState: DragDropState = {
  dropTargets: {},
  dragSources: {},
}

const dragDropSlice = createSlice({
  name: 'dragDrop',
  initialState,
  reducers: {
    addTarget(state, action) {
      const { data, id } = action.payload
      state.dropTargets[id] = data
    },
    updateTarget(state, action) {
      const { data, id } = action.payload
      const current = state.dropTargets[id]
      state.dropTargets[id] = { ...current, ...data }
    },
    addSource(state, action) {
      const { data, id } = action.payload
      state.dragSources[id] = data
    },
    updateSource(state, action) {
      const { data, id } = action.payload
      const current = state.dragSources[id]
      state.dragSources[id] = { ...current, ...data }
    },
  },
})

export const {
  addTarget,
  addSource,

  updateTarget,
  updateSource,
} = dragDropSlice.actions

export const selectTargets = (state) => state.dragDrop.dropTargets
export const selectSources = (state) => state.dragDrop.dragSources

export const selectTargetById = (state, id) => {
  return selectTargets(state)[id]
}
export const selectSourceById = (state, id) => {
  return selectSources(state)[id]
}

export const selectDragMonitorById = (state, id) => {
  const target = selectTargets(state)[id]
  return target ? target.monitor : null
}

export default dragDropSlice.reducer
