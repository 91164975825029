/**
 * This file provides a socketio connection to subcomponents via context
 */
import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'

import config from 'config'

import { SocketContext } from './SocketContext'

export const SocketProvider = ({ children, userId }) => {
  const [socket, setSocket] = useState<SocketIOClient.Socket>()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (!userId || !process.browser) return
    const socketInstance = io(
      `${config('WS_PROTOCOL')}://${config('WS_HOST')}?userId=${userId}`,
      {
        path: '/core',
        transports: ['websocket', 'polling'],
      }
    )
    setSocket(socketInstance)

    socketInstance.on('connect', () => {
      console.log(`[Socket.io] Connected as ${socketInstance.id}`)
      setReady(true)
    })
    socketInstance.on('disconnect', () => {
      console.log(`[Socket.io] Disconnected as ${socketInstance.id}`)
    })
  }, [userId])

  const _socket = ready ? socket : undefined

  return (
    <SocketContext.Provider
      value={{
        socket: _socket,
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}
