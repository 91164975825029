import { gql, useMutation, useQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'

import { GoogleOAuthContext } from './authContext'

import {
  getJwt,
  googleOAuthLogin,
  googleOAuthLogout,
  validateJwtAndGetUser,
} from '.'

const GET_USER = gql`
  query GetUser($email: String!) {
    userByEmail(email: $email) {
      id
      profileImageUrl
    }
  }
`

export const useAuthContext2 = () => {
  const { state, dispatch } = useContext(GoogleOAuthContext)
  return { state, dispatch }
}

/**
 * Handles checking for a JWT and validating it, once validated it will
 * set the user
 */
export const UserFromJwt = () => {
  const { dispatch } = useAuthContext2()
  useEffect(() => {
    const jwt = getJwt()
    if (jwt) {
      console.log('got jwt', jwt)
      // validates the jwt and sets the user in useAuthContext2
      validateJwtAndGetUser(jwt)(dispatch)
    } else {
      dispatch({
        type: 'AUTH_SET_READY',
        isReady: true,
      })
    }
  }, [])

  return null
}

export const useGoogleOAuthUser = () => {
  const { state } = useContext(GoogleOAuthContext)
  const { data } = useQuery(GET_USER, {
    skip: !state.isReady || !state.user,
    variables: { email: state.user?.email },
  })
  const user = state.user
    ? {
        ...state.user,
        gammaUserId: data?.userByEmail?.id,
        profileImageUrl: data?.userByEmail?.profileImageUrl,
      }
    : state.user
  return {
    user,
    isReady: state.isReady && (!state.user || !!data?.userByEmail?.id),
  }
}

export const useGoogleOAuthLogout = () => {
  const { dispatch } = useContext(GoogleOAuthContext)
  return () => googleOAuthLogout(dispatch)
}

export const useGoogleOAuthLogin = () => {
  const { dispatch } = useContext(GoogleOAuthContext)
  return () => googleOAuthLogin(dispatch)
}
