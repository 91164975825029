// 1. Import `extendTheme`
import { extendTheme } from '@chakra-ui/react'

const BUTTON_STYLES_DARK = {
  backgroundColor: 'rgba(30, 30, 30, 0.6)',
  _hover: { backgroundColor: 'rgba(60, 60, 60, 0.7)' },
  _active: { backgroundColor: 'rgba(200, 200, 200, .3)' },
  transition: 'border-color 200ms ease-out, background-color 100ms ease-out',
  transform:
    '200ms cubic-bezier(0.34, 1.56, 0.64, 1), width 300ms cubic-bezier(0.34, 1.56, 0.64, 1)',
  border: '0px',
  outline: 'none',
  height: '40px',
  paddingRight: '20px',
  paddingLeft: '20px',
  borderWidth: '0px',
}

// 2. Call `extendTheme` and pass your custom values
export const gammaTheme = extendTheme({
  styles: {
    global: {
      'html, body': {
        fontFamily: 'body',
        fontWeight: 400,
        background: 'var(--chakra-colors-gray-100)',
      },
      '#__next': {
        height: '100%',
      },
    },
  },
  fontSizes: {},
  fonts: {
    body: "'Inter', sans-serif",
    heading: "'Inter', sans-serif",
  },
  fontWeights: {
    thin: 400,
    medium: 500,
    bold: 600,
  },
  components: {
    Text: {
      baseStyle: {
        fontWeight: 400,
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: 500,
      },
    },
    Input: {
      parts: ['field, addon'],
      variants: {
        dark: {
          field: {
            borderRadius: '999px',
            transition:
              'border-color 200ms ease-out, background-color 100ms ease-out, transform 200ms cubic-bezier(0.34, 1.56, 0.64, 1), width 300ms cubic-bezier(0.34, 1.56, 0.64, 1)',
            boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.3)',
            backdropFilter: 'blur(8px)',
            backgroundColor: 'rgba(30, 30, 30, 0.6)',
            border: '1px solid rgba(255, 255, 255, 0.4)',
            color: 'white',
            height: '40px',
            padding: '6px 16px',
            _focus: { boxShadow: '0px 0px 0px 4px rgba(13, 110, 253, 0.25)' },
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: '999px',
      },
      variants: {
        dark: BUTTON_STYLES_DARK,
        glass: {
          bg: 'rgba(0,0,0,.12)',
          style: {
            backdropFilter: 'blur(40px)',
          },
          _hover: {
            bg: 'rgba(60,60,60,.42)',
          },
        },
        toolbar: {
          w: 'auto',
          minW: '0px',
          margin: '0px',
          px: '5px',
          _hover: { bg: 'gray.100' },
          borderRadius: '3px',
          mt: '4px',
          mb: '4px',
          h: '1.5rem',
        },
        activeToolbar: {
          w: 'auto',
          minW: '0px',
          margin: '0px',
          px: '5px',
          bg: 'blue.200',
          color: 'blue.800',
          borderRadius: '3px',
          _hover: { bg: 'blue.300' },
          mt: '4px',
          h: '1.5rem',
        },
      },
    },
    IconButton: {
      baseStyle: {
        borderRadius: '999px',
      },
      variants: {
        dark: BUTTON_STYLES_DARK,
      },
    },
    Modal: {
      parts: [
        'overlay',
        'dialogContainer',
        'dialog',
        'header',
        'closeButton',
        'body',
        'footer',
      ],
      variants: {
        dark: {
          overlay: {
            // bg: 'red',
          },
          dialog: {
            bg: 'blackAlpha.700',
            color: 'white',
          },
          header: {
            // color: white
          },
        },
      },
    },
  },
})
