import { createSlice } from '@reduxjs/toolkit'

export interface CardCommentsState {
  comments: {
    [key: string]: [Comment] | []
  }
}

interface Comment {
  id: string
  text: string
  createdAt: Date
  commenter: {
    id: string
    firstName: string
    lastName: string
    profileImageUrl: string
  }
}

const initialState: CardCommentsState = {
  comments: {},
}

const cardCommentsSlice = createSlice({
  name: 'cardComments',
  initialState,
  reducers: {
    updateComments(state, action) {
      const { cardId, comments } = action.payload
      state.comments[cardId] = comments
    },
  },
})

export const { updateComments } = cardCommentsSlice.actions

// Selectors
export const selectComments = (state) => state.cardComments.comments

export default cardCommentsSlice.reducer
