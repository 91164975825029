import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache,
} from '@apollo/client'
import { Center, Spinner } from '@chakra-ui/react'
import { enums } from '@optimizely/optimizely-sdk'
import {
  createInstance,
  OptimizelyProvider,
  ReactSDKClient,
} from '@optimizely/react-sdk'
import config from 'config'
import firebase from 'firebase/app'
import { httpLink, setJwtLink, wsLink } from 'modules/api/link'
import { AuthProvider, useAuth } from 'modules/auth'
import { GoogleOAuthProvider } from 'modules/googleOAuth/authContext'
import { authReducer } from 'modules/googleOAuth/authReducer'
import { UserFromJwt } from 'modules/googleOAuth/hooks'
import { SocketProvider } from 'modules/socket/SocketProvider'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { APP_HOST, DEV_FIREBASE_CONFIG } from '../../firebaseconfig.js'
import reduxStore from '../redux'

export const AppProvider = ({ children }) => {
  const isSSR =
    typeof window === 'undefined' || typeof window.navigator === 'undefined'
  const [initialized, setInitialized] = React.useState(false)
  const auth = useAuth(initialized)
  const [optimizelyReady, setOptimizelyReady] = useState(false)
  const optimizely = useRef<ReactSDKClient | null>(null)
  const apolloClient = useRef<ApolloClient<any> | null>(null)

  useEffect(() => {
    if (isSSR || !auth.user || optimizely.current) return
    performance.mark('createInstanceStart')
    optimizely.current = createInstance({
      sdkKey: config('OPTIMIZELY_SDK_KEY'),
      logLevel: enums.LOG_LEVEL.WARNING,
    })
    optimizely.current.setUser({ id: auth.user.uid })
    optimizely.current.onReady().then((result) => {
      if (result.success) {
        performance.mark('createInstanceOnReadySuccess')
        setOptimizelyReady(true)
      }
    })
  }, [auth])

  useEffect(() => {
    if (isSSR) return
    const hasSubscriptionOperation = ({ query: { definitions } }) =>
      definitions.some(
        ({ kind, operation }) =>
          kind === 'OperationDefinition' && operation === 'subscription'
      )

    const terminalLink = ApolloLink.split(
      hasSubscriptionOperation,
      wsLink,
      httpLink
    )
    apolloClient.current = new ApolloClient({
      link: from([setJwtLink, terminalLink]),
      cache: new InMemoryCache(),
    })
  })

  useEffect(() => {
    if (isSSR) return
    fetch(`${APP_HOST}/__/firebase/init.json`)
      .then(
        (response) => response.json(),
        () => DEV_FIREBASE_CONFIG
      )
      .then((config) => {
        if (firebase.apps.length > 0) return
        firebase.initializeApp(config)
        setInitialized(true)
      })
  }, [])
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    isReady: false,
  })

  if (
    !auth.user ||
    !optimizelyReady ||
    !optimizely.current ||
    !apolloClient.current
  )
    return (
      <Center h="100vh" bg="gray.200">
        <Spinner size="lg" color="white" />
      </Center>
    )

  return (
    <ReduxProvider store={reduxStore}>
      <OptimizelyProvider optimizely={optimizely.current}>
        <AuthProvider value={auth}>
          <GoogleOAuthProvider value={{ state, dispatch }}>
            <UserFromJwt />
            <ApolloProvider client={apolloClient.current}>
              <SocketProvider userId={auth.user ? auth.user.uid : null}>
                {children}
              </SocketProvider>
            </ApolloProvider>
          </GoogleOAuthProvider>
        </AuthProvider>
      </OptimizelyProvider>
    </ReduxProvider>
  )
}
