import { createContext, Dispatch } from 'react'

import { AuthActions, AuthReducerState } from './authReducer'

export const GoogleOAuthContext = createContext<{
  state: AuthReducerState
  dispatch: Dispatch<AuthActions>
}>({
  state: { user: null, isReady: false },
  dispatch: () => {},
})

export const GoogleOAuthProvider = GoogleOAuthContext.Provider
