import { configureStore } from '@reduxjs/toolkit'
import cardCommentsReducer from 'modules/cards/reducer'
import { default as dragDropReducer } from 'modules/cardsDemo/reducer'
import dailyReducer from 'modules/peer/daily/reducer'

// All stores must be registered here.
// Code splitting ideas: https://redux.js.org/recipes/code-splitting
const store = configureStore({
  reducer: {
    // Define a top-level state field named `daily`, handled by `dailyReducer`
    daily: dailyReducer,
    dragDrop: dragDropReducer,
    cardComments: cardCommentsReducer,
  },
})

export default store
