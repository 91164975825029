import { Dispatch } from 'react'
import { getCookie, removeCookie, setCookie } from 'utils/cookie'
import { v4 } from 'uuid'

import config from '../../config'
import { AuthActions } from './authReducer'
import { CLIENT_ID } from './config'
import { User } from './types'

type OAuthParams = {
  client_id: string
  scope: string
}

const JWT_COOKIE = 'gamma_jwt'
const JWT_COOKIE_EXPIRY = 30

const CLIENT_ID_COOKIE = 'gamma_client_id'
const CLIENT_ID_COOKIE_EXPIRY = 365 // in days

/**
 * Retrieves or generates a client id
 */
export const getClientId = () => {
  if (!process.browser) {
    return
  }
  const existingClientId = getCookie(CLIENT_ID_COOKIE)
  if (existingClientId) {
    // refresh
    setCookie(CLIENT_ID_COOKIE, existingClientId, CLIENT_ID_COOKIE_EXPIRY)
    return existingClientId
  }

  const clientId = v4()
  setCookie(CLIENT_ID_COOKIE, clientId, CLIENT_ID_COOKIE_EXPIRY)
  return clientId
}

export const constructLoginUrl = (oauthParams: OAuthParams) => {
  const redirectUri = `${window.location.protocol}//${window.location.host}`
  const params = new URLSearchParams()
  params.set('redirect_uri', `${redirectUri}/auth/handler`)
  params.set('prompt', 'consent')
  params.set('response_type', 'code')
  params.set('client_id', oauthParams.client_id)
  params.set('scope', oauthParams.scope)

  params.set('access_type', 'offline')
  params.set('flowName', 'GeneralOAuthFlow')

  const BASE_URL =
    'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount'

  return `${BASE_URL}?${params.toString()}`
}

export type OAuthResult = {
  jwt: string
}

/**
 * This function opens up a google oauth login and returns a promise that resolves
 * with the users JWT
 */
export const openSigninWindow = (() => {
  const USER_EMAIL_SCOPE = 'https://www.googleapis.com/auth/userinfo.email'
  const USER_PROFILE_SCOPE = 'https://www.googleapis.com/auth/userinfo.profile'
  const OAUTH_SCOPES = [USER_EMAIL_SCOPE, USER_PROFILE_SCOPE]

  if (!process.browser) {
    return () => {}
  }

  const url = constructLoginUrl({
    client_id: CLIENT_ID,
    scope: OAUTH_SCOPES.join(' '),
  })
  let windowObjectReference: any = null
  let previousUrl: string | null = null

  const host = window.location.protocol + '//' + window.location.host

  return (name): Promise<OAuthResult> => {
    return new Promise((resolve, reject) => {
      const receiveMessage = ({ data, origin }) => {
        // Do we trust the sender of this message? (might be
        // different from what we originally opened, for example).
        if (origin !== host) {
          return
        }
        if (data.type === 'oauth-response') {
          const { result } = data
          // alert(JSON.stringify(result, null, '  '))

          // const redirectUrl = `/auth/google/login${payload}`
          // window.location.pathname = redirectUrl
          if (
            typeof windowObjectReference == 'object' &&
            windowObjectReference != null
          ) {
            windowObjectReference.close()
            windowObjectReference = null
          }
          resolve(result)
          previousUrl = null
        }
      }
      // remove any existing event listeners
      window.removeEventListener('message', receiveMessage)

      // window features
      const strWindowFeatures =
        'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'

      if (windowObjectReference === null || windowObjectReference.closed) {
        /* if the pointer to the window object in memory does not exist
          or if such pointer exists but the window was closed */
        windowObjectReference = window.open(url, name, strWindowFeatures)
      } else if (previousUrl !== url) {
        /* if the resource to load is different,
          then we load it in the already opened secondary window and then
          we bring such window back on top/in front of its parent window. */
        windowObjectReference = window.open(url, name, strWindowFeatures)
        windowObjectReference.focus()
      } else {
        /* else the window reference must exist and the window
          is not closed; therefore, we can bring it back on top of any other
          window with the focus() method. There would be no need to re-create
          the window or to reload the referenced resource. */
        windowObjectReference.focus()
      }

      // add the listener for receiving a message from the popup
      window.addEventListener(
        'message',
        (event) => receiveMessage(event),
        false
      )
      // assign the previous URL
      previousUrl = url
    })
  }
  // setCode(urlParams.get('code'))
})()

export const getJwt = (): string | undefined => {
  if (!process.browser) {
    return
  }
  return getCookie(JWT_COOKIE)
}

export const googleOAuthLogin = async (dispatch: Dispatch<AuthActions>) => {
  const res = await openSigninWindow('window1')
  if (!res) {
    // something went wrong
    return
  }

  return await validateJwtAndGetUser(res.jwt)(dispatch)
}

export const googleOAuthLogout = async (dispatch: Dispatch<AuthActions>) => {
  const jwt = getJwt()
  if (!jwt) {
    return
  }
  return await invalidateTokenAndLogoutUser(jwt)(dispatch)
}

type FetchUserResponse =
  | {
      isError: true
      message: string
    }
  | {
      isError: false
      result: { user: User }
    }

export const validateJwtAndGetUser = (jwt: string) => async (
  dispatch: Dispatch<AuthActions>
) => {
  dispatch({
    type: 'AUTH_SET_READY',
    isReady: false,
  })
  const req = await fetch(`${config('API_HOST')}/auth/user`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  const res = (await req.json()) as FetchUserResponse
  if (!res.isError) {
    console.log('got user and setting', res)
    dispatch({
      type: 'AUTH_SET_USER',
      user: res.result.user,
    })
    setCookie(JWT_COOKIE, jwt, JWT_COOKIE_EXPIRY)
  } else {
    removeCookie(JWT_COOKIE)
  }
  dispatch({
    type: 'AUTH_SET_READY',
    isReady: true,
  })

  return res
}

export const invalidateTokenAndLogoutUser = (jwt: string) => async (
  dispatch: Dispatch<AuthActions>
) => {
  dispatch({
    type: 'AUTH_SET_READY',
    isReady: false,
  })
  const req = await fetch(`${config('API_HOST')}/auth/user/logout`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  const res = await req.json()
  if (!res.isError) {
    dispatch({
      type: 'AUTH_SET_USER',
      user: null,
    })
    removeCookie(JWT_COOKIE)
  }
  dispatch({
    type: 'AUTH_SET_READY',
    isReady: true,
  })
}
