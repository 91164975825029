import { Reducer } from 'react'

import { User } from './types'

export type AuthReducerState = {
  user: User | null
  isReady: boolean
}

type SetUserAction = {
  type: 'AUTH_SET_USER'
  user: User | null
}

type SetReadyAction = {
  type: 'AUTH_SET_READY'
  isReady: boolean
}

export type AuthActions = SetUserAction | SetReadyAction

const initialState: AuthReducerState = {
  user: null,
  isReady: false,
}

export const authReducer: Reducer<AuthReducerState, AuthActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'AUTH_SET_USER':
      console.log('AUTH_SET_USER: ', action.user)
      return {
        ...state,
        user: action.user,
      }
    case 'AUTH_SET_READY':
      console.log('AUTH_SET_READY: ', action.isReady)
      return {
        ...state,
        isReady: action.isReady,
      }
    default:
      return state
  }
}
